import { hasuraGql } from '@/services/hasura-client';
import { getAppClientData } from '@/services/app-client.client';
import { appClientApiPath } from '@/utils/constants';
import { getActiveEnvUrl } from '@/core/env-and-content-src/env-url-storage';
import {
  getEnvDetails,
  getEnvDetailsFromAppClientData,
  getEnvWebsocketUrl,
  isUrlDevelopment,
} from '@/core/env-and-content-src/utils';
import {
  ContentSrc,
  Env,
} from '@/core/env-and-content-src/env-and-content-src.types';
import { appApiGql, KsAppApiGqlClientError } from '@/services/app-api-client';
import type { SiteMeta } from '@knapsack/hasura-gql-client';
import type { SiteLoadResults } from '../../xstate/machines/app/sub-states/site.xstate-types';
import { extractMetaState } from './extract-meta-state';

type SiteLoad = Promise<SiteLoadResults>;

export type SiteLoadErrorInfo =
  | { type: 'siteId-not-found'; siteId: string }
  | {
      type: 'outdated-app-client';
      currentVersion: string;
      requiredVersion: string;
    }
  | {
      type: 'app-client-connection-failed';
      appClientUrl: string;
    }
  | {
      type: 'app-client-deployments-not-set-up';
    }
  | {
      type: 'private-site.user-not-logged-in';
      siteId: string;
    }
  | {
      type: 'private-site.user-not-allowed-to-view-site';
      siteId: string;
    }
  | { type: 'unknown'; message: string };

export class SiteLoadError extends Error {
  info: SiteLoadErrorInfo;
  constructor(info: SiteLoadErrorInfo) {
    switch (info.type) {
      case 'siteId-not-found':
        super(`Site ID "${info.siteId}" not found`);
        break;
      case 'outdated-app-client':
        super(`App Client is outdated`);
        break;
      case 'app-client-connection-failed':
        super(
          `App Client connection failed, please ensure this url is accessible: ${info.appClientUrl}`,
        );
        break;
      case 'unknown':
        super(info.message);
        break;
      case 'app-client-deployments-not-set-up':
        super(
          'App Client deployments are not set up yet, this workspace only can be ran locally.',
        );
        break;
      case 'private-site.user-not-logged-in':
        super('This is a private site. You must be logged in.');
        break;
      case 'private-site.user-not-allowed-to-view-site':
        super(
          'This workspace is private and you do not have permission to view it.',
        );
        break;
      default: {
        // `never` uses TS to ensure we handle all cases in the switch
        const _exhaustiveCheck: never = info;
        super(`SiteLoadError`);
        break;
      }
    }
    this.name = 'SiteLoadError';
    this.info = info;
  }
}

// Site running local
async function loadSiteFromClientUrl({
  appClientUrl,
}: {
  appClientUrl: string;
}): SiteLoad {
  try {
    const _appClientApiEndpoint = new URL(
      appClientApiPath,
      appClientUrl,
    ).toString();
  } catch (error) {
    throw new SiteLoadError({
      type: 'unknown',
      message: `This is not a proper url: "${appClientUrl}"`,
    });
  }

  const { metaState, ...appClientData } = await getAppClientData({
    appClientUrl,
  }).catch((error) => {
    console.error(`Error loading App Client Data`, error);
    throw new SiteLoadError({
      type: 'app-client-connection-failed',
      appClientUrl,
    });
  });

  const appClientMeta = extractMetaState(metaState);

  const info = await hasuraGql.GetSiteBasicInfo({
    siteId: appClientMeta.knapsackCloudSiteId,
  });
  if (!info?.site?.id) {
    throw new SiteLoadError({
      type: 'siteId-not-found',
      siteId: appClientMeta.knapsackCloudSiteId,
    });
  }
  const {
    gitProvider,
    planId,
    status,
    isPrivate,
    latestUrl,
    orgId,
    title,
    repoUrl,
    id: siteId,
  } = info.site;

  const env: Env = isUrlDevelopment(appClientUrl)
    ? {
        type: 'development',
        appClientMeta,
        url: appClientUrl,
        renderersMeta: appClientData.patternsState.renderers,
        assetSetsState: appClientData.assetSetsState,
        websocketsEndpoint: getEnvWebsocketUrl({
          envUrl: appClientUrl,
          websocketsPort: appClientMeta.websocketsPort,
        }),
      }
    : {
        type: 'preview',
        appClientMeta,
        url: appClientUrl,
        renderersMeta: appClientData.patternsState.renderers,
        assetSetsState: appClientData.assetSetsState,
      };
  const contentSrc: ContentSrc = {
    type: 'current-env-server',
  };
  const meta: SiteMeta = {
    siteId,
    isPrivate,
    repoUrl,
    title,
    gitProviderType: gitProvider,
    status,
    plan: planId,
    orgId,
    prodEnvUrl: latestUrl,
  };
  const { convertTokenGroupToData, getTokenCollectionsCss } = await import(
    '@knapsack/design-token-utils'
  );
  const tokenData = convertTokenGroupToData(appClientData.tokensSrc);
  const tokenStyles = getTokenCollectionsCss({
    tokenData,
    minimize: true,
  });
  return {
    appClientData,
    site: {
      env,
      meta,
      contentSrc,
    },
    tokenData,
    tokenStyles,
  };
}

const getSiteContentAndMeta = async ({
  siteId,
  instanceId,
}: {
  siteId: string;
  instanceId: string;
}) => {
  try {
    return await appApiGql.getSiteContentAndMeta({ instanceId });
  } catch (e) {
    if (e instanceof KsAppApiGqlClientError) {
      if (e.errorCode === 'ks.private-site.user-not-logged-in') {
        throw new SiteLoadError({
          type: 'private-site.user-not-logged-in',
          siteId,
        });
      }
      if (e.errorCode === 'ks.private-site.user-not-allowed-to-view-site') {
        throw new SiteLoadError({
          type: 'private-site.user-not-allowed-to-view-site',
          siteId,
        });
      }
    }
    throw new SiteLoadError({
      type: 'unknown',
      message: e.message,
    });
  }
};

export async function loadSiteById({
  siteId,
  instanceId,
}: {
  siteId: string;
  instanceId: string;
}): Promise<SiteLoadResults> {
  if (siteId === 'custom') {
    throw new Error(`Cannot use this function to load siteId "custom".`);
  }

  const {
    site: {
      appClientDataInfo: {
        appClientData,
        lastDataChangeId,
        instanceStatus,
        gitBranch,
        lastCommittedDataChangeId,
        tokens,
      },
      meta,
    },
  } = await getSiteContentAndMeta({ siteId, instanceId });
  let tokenData = tokens?.tokenData;
  let tokenStyles = tokens?.tokenStyles;
  if (!tokenData && Object.keys(appClientData.tokensSrc).length > 0) {
    console.time('tokens conversion');
    const { convertTokenGroupToData, getTokenCollectionsCss } = await import(
      '@knapsack/design-token-utils'
    );
    tokenData = convertTokenGroupToData(appClientData.tokensSrc);
    tokenStyles = getTokenCollectionsCss({
      tokenData,
      minimize: true,
    });
    console.timeEnd('tokens conversion');
    console.debug(
      `tokens conversion: data converted locally since API returned no data`,
    );
  } else {
    console.debug(`tokens conversion: data returned from API`, {
      fromCache: tokens?.fromCache,
    });
  }

  const {
    orgId,
    prodEnvUrl,
    repoUrl,
    siteId: loadedSiteId,
    title,
    isPrivate,
    gitProviderType,
    plan,
    status,
  } = meta;

  if (!loadedSiteId)
    throw new SiteLoadError({
      type: 'siteId-not-found',
      siteId: loadedSiteId,
    });
  if (!prodEnvUrl)
    throw new SiteLoadError({
      type: 'app-client-deployments-not-set-up',
    });

  // null here means no environment url, therefore there is only PROD url
  const activeEnvUrl = getActiveEnvUrl({ siteId });

  // Saves a network request here by building env if we already have appClientData
  const env = activeEnvUrl
    ? await getEnvDetails({ envUrl: activeEnvUrl, prodEnvUrl })
    : getEnvDetailsFromAppClientData({
        appClientData,
        envUrl: prodEnvUrl, // no activeEnvUrl means prod must be envUrl
        prodEnvUrl,
      });
  if (env instanceof Error) {
    throw new Error(
      `Extracting meta from appClientData failed. Errors: ${env.message}`,
    );
  }

  const siteMeta: SiteMeta = {
    siteId,
    title,
    isPrivate,
    repoUrl,
    gitProviderType,
    status,
    plan,
    orgId,
    prodEnvUrl,
  };

  // Default to latest for SiteLoadResults, override contentSrc cloud authoring branches
  const defaultContentSrc: ContentSrc = {
    type: 'cloud-authoring',
    instance: {
      type: 'latest',
    },
  };
  const siteLoadResults: SiteLoadResults = {
    appClientData,
    site: {
      contentSrc: defaultContentSrc,
      env,
      meta: siteMeta,
    },
    tokenData,
    tokenStyles,
  };

  switch (instanceId) {
    case 'draft': {
      throw new Error(`InstanceTag "draft" is not yet implemented.`);
    }
    case 'latest': {
      return siteLoadResults;
    }
    default: {
      // We won't have this if instance failed to load
      if (!instanceStatus) {
        return loadSiteById({ siteId, instanceId: 'latest' });
      }

      const cloudAuthoringContentSrc: ContentSrc = {
        type: 'cloud-authoring',
        instance: {
          type: 'branch',
          instanceId,
          instanceStatus,
          lastCommittedDataChangeId,
          gitBranch,
          title,
        },
      };
      siteLoadResults.site.contentSrc = cloudAuthoringContentSrc;

      return siteLoadResults;
    }
  }
}

export async function loadSiteFromUrlParams(
  info:
    | {
        type: 'site';
        siteId: string;
        instanceId: string;
      }
    | {
        type: 'custom';
        customUrl: string;
      },
): SiteLoad {
  console.time('loadSite');
  switch (info.type) {
    case 'site': {
      const { siteId, instanceId } = info;
      const results = await loadSiteById({
        siteId,
        instanceId,
      });
      console.timeEnd('loadSite');
      return results;
    }
    case 'custom': {
      const results = await loadSiteFromClientUrl({
        appClientUrl: info.customUrl,
      });
      console.timeEnd('loadSite');
      return results;
    }
    default: {
      const _exhaustiveCheck: never = info;
      throw new Error(`Unhandled info type: ${JSON.stringify(info)}`);
    }
  }
}
